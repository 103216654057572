@layer base {
  .main {
  flex: 1;
  padding: var(--sk-space-24) 0;
  background-color: var(--sk-color-grey-025);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
  padding: var(--sk-space-24);
  border: var(--sk-border-width-2) solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-white);
}

.notice {
  display: flex;
  align-items: center;
  padding: var(--sk-space-12) var(--sk-space-16);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-purple-100);
  color: var(--sk-color-grey-800);
  gap: var(--sk-space-16);
}

.notice .icon {
  color: var(--sk-color-purple-500);
}

.notice span {
  flex: 1;
}
}