@layer base {
  .main {
  display: flex;
  justify-content: center;
}

.item {
  box-sizing: border-box;
  min-width: calc(var(--sk-size-40) + var(--sk-size-4));
  min-height: calc(var(--sk-size-40) + var(--sk-size-4));
  padding: 0;
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  text-align: center;
}

.item___ellipsis {
  min-width: inherit;
  margin: 0 var(--sk-size-4);
  padding-top: var(--sk-size-8);
}

.item___current {
  font-weight: var(--sk-typography-body-1-semibold-font-weight);
  pointer-events: none;
}

@media (width < 410px) {
  .item___ellipsis ~ .item___current_close_sibling:not(.item___first, .item___last) {
    display: none;
  }
}

@media (--sk-small-viewport-only) {
  .item___current_sibling:not(.item___ellipsis, .item___current_close_sibling, .item___first, .item___last) {
    display: none;
  }
}

@media (--sk-medium-viewport) {
  .item___mobile_ellipsis {
    display: none;
  }
}

}