@layer base {
  .main {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--sk-space-32);
  background-color: var(--sk-color-grey-025);
}

.primaryFilters {
  /*
    With this z-index, this flex item will be above the secondary filters
    that are positioned and after in the order of appearance in the HTML.
    current stacking context : filtersContainer
  */
  z-index: var(--z-up-in-the-current-stacking-context);
}

.results {
  display: flex;
  flex-direction: column;
  margin: var(--sk-space-12) 0 var(--sk-space-32);
  padding: 0;
  list-style-type: none;
  gap: var(--sk-space-16);
}

.saveSearchButton {
  display: flex;
  position: sticky;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  bottom: var(--sk-space-24);
  left: 0;
  justify-content: center;
  width: 100%;
  margin-top: var(--sk-space-32);
  margin-right: auto;
  margin-left: auto;
}

@media (--sk-medium-viewport) {
  .saveSearchButton {
    width: auto;
  }

  .saveSearchButton___isSaveSearchButtonInSearchBarEnabled {
    display: none;
  }

  .illustration {
    z-index: var(--z-up-in-the-current-stacking-context) !important;
    top: var(--sk-space-64) !important;
    right: var(--sk-space-40) !important;
    transform: rotate(0deg) !important;
  }
}

@media (--sk-large-viewport) {
  .illustration {
    top: var(--sk-space-16) !important;
    right: var(--sk-space-24) !important;
  }
}

}