@layer base {
  .main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--sk-space-64) 0;
  text-align: center;
  gap: var(--sk-space-16);
}

.main img {
  max-width: 75%;
  height: auto;
  margin-bottom: var(--sk-space-32);
}

@media (--sk-medium-viewport) {
  .main img {
    max-width: inherit;
  }
}

}