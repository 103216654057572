@layer base {
  .main {
  --local-row-gap: var(--sk-space-16);

  padding-top: var(--sk-space-40);
  padding-bottom: var(--sk-size-40);
}

.main.main__primary:has(+ .main) {
  padding-bottom: 0;
}

.main.main__primary + .main {
  padding-top: var(--sk-space-40);
}

.main.main__secondary + .main {
  padding-top: var(--sk-space-32);
}

.main.main__secondary {
  position: relative;
}

.main.main__secondary::before {
  content: "";
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context);
  top: 0;
  bottom: 0;
  width: 100vw;
  margin-left: 50%;
  background-color: var(--sk-color-black);
  translate: -50%;
}

@media (--sk-medium-viewport) {
  .main {
    --local-row-gap: var(--sk-space-24);

    padding-top: var(--sk-size-88);
    padding-bottom: var(--sk-size-88);
  }

  .main.main__primary + .main {
    padding-top: var(--sk-size-56);
  }

  .main.main__secondary + .main {
    padding-top: var(--sk-space-64);
  }
}

.head {
  display: flex;
  flex-direction: column;
}

.subtitle {
  margin-top: var(--sk-space-16);
}

.description {
  margin-top: var(--sk-space-8);
}

.illustration {
  display: none;
  pointer-events: none;
  user-select: none;
}

@media (--sk-medium-viewport) {
  .illustration {
    display: block;
    position: absolute;
    z-index: var(--z-down-in-the-current-stacking-context);
    top: var(--sk-size-32);
    right: 0;
  }
}

.filters {
  display: flex;
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  flex-direction: column;
  padding: var(--sk-space-16);
  border-radius: var(--sk-radius-24);
  background-color: var(--sk-color-black);
  color: var(--sk-color-white);
  gap: var(--sk-space-16);
}

.results {
  display: flex;
  position: relative;
  z-index: var(--z-reset-in-the-current-stacking-context);
  flex-direction: column;
  gap: var(--sk-space-24);
}

.results--loading {
  animation: results-loading 1s linear infinite alternate;
  color: currentcolor;
}

@keyframes results-loading {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

}