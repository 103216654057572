@layer base {
  .form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--sk-space-16);
}

.select {
  width: 100%;
}

@media (--sk-medium-viewport) {
  .form {
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .select {
    display: flex;
    flex: 1;
    flex-basis: 100%;
    flex-direction: column;
  }
}

}