@layer base {
  .main {
  display: flex;
  flex-wrap: wrap;
  margin: 0; /* reset browsers style */
  padding: 0; /* reset browsers style */
  list-style: none; /* reset browsers style */
  gap: var(--sk-space-8);
}

}