@layer base {
  /* Test styles */
.wrapper {
  padding: var(--sk-space-16) 0;
  background-color: var(--sk-color-grey-100);
}

.placeholder {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: var(--sk-color-grey-200);
}

.wrapper hr {
  margin: var(--sk-space-8) var(--sk-space-16);
}

@media (--sk-medium-viewport) {
  .wrapper hr {
    margin: var(--sk-space-16) var(--sk-space-40);
  }
}

@media (--sk-large-viewport) {
  .wrapper hr {
    max-width: 1232px;
    margin: var(--sk-space-16) auto;
  }
}

}