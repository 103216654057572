@layer base {
  .list {
  padding: 0;
}

.list__item {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list__item:not(:last-child) {
  margin-bottom: var(--sk-space-24);
}

.addButton {
  width: fit-content;
  margin: 0 auto;
}

}