@layer base {
  .wrapperLogo {
  height: var(--sk-size-64);
}

.signature {
  padding: var(--sk-space-24) 0;
}

.signature > picture {
  width: var(--sk-size-64);
  height: var(--sk-size-64);
  margin-right: var(--sk-size-4);
  transition: box-shadow 300ms ease-out;
  border-radius: var(--sk-radius-8);
  box-shadow: 0 2px 12px 0 rgb(217 217 217 / 50%);
}

.signature > picture:hover {
  border-radius: var(--sk-radius-8);
  box-shadow: 0 2px 12px 0 rgb(217 217 217 / 100%);
}

.link > picture:hover {
  cursor: pointer;
}

.content {
  overflow: hidden; /* Usefull to apply text-overflow to his div child */
}

.description {
  color: var(--sk-color-grey-700);
}

.description__item___desktopOnly {
  display: none;
}

@media (--sk-medium-viewport) {
  .description {
    overflow: hidden;
    color: var(--sk-color-grey-700);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .description__item {
    display: inline;
  }

  .description__item___desktopOnly {
    display: initial;
  }

  .description__item___withSeparator::before {
    content: "•";
    margin: 0 var(--sk-space-4);
  }
}

}