@layer base {
  /**
 Layout
*/

.wrapper {
  position: relative;
  height: fit-content;
}

/* TODO: provide a props to FoLayout which manage the background kind (grey or white) */
.foLayout {
  background-color: var(--sk-color-white);
}

.contentLayout {
  padding-top: var(--sk-space-24);
  padding-bottom: var(--sk-space-24);
}

@media (--sk-medium-viewport) {
  .contentLayout {
    padding-bottom: var(--sk-space-80);
  }
}

@media (--sk-large-viewport) {
  .contentLayout {
    position: relative;
    transition: transform 0.5s;
  }

  .contentLayout___withAside {
    transform: translateX(-208px); /* (88px * 2) + (16px * 2)  */
  }
}

}