@layer base {
  .list {
  display: flex;
  flex-direction: column;
  gap: var(--sk-size-24);
  margin-top: var(--sk-size-24);
}

.feedback {
  display: flex;
  flex-direction: column;
  gap: var(--sk-size-12);
}
}