@layer base {
  .main {
  padding-bottom: var(--sk-space-24);
}

@media (--sk-medium-viewport) {
  .main {
    margin-top: var(--sk-space-40);
  }
}

.publicationDate {
  padding-bottom: var(--sk-space-24);
  color: var(--sk-color-grey-600);
}

@media print {
  /* decrease spacing with candidacy details on the printed version of the page */

  .main {
    padding-bottom: var(--sk-space-4);
  }
}

}