@layer base {
  .main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--sk-space-8);
}

.link___reset {
  color: inherit;
  text-decoration: none;
}

}