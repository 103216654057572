@layer base {
  /* LAYOUT */

@media (--sk-medium-viewport) {
  .illustration {
    display: none;
  }
}

@media (--sk-large-viewport) {
  .illustration {
    display: block;
    margin-top: var(--sk-space-24);
    transform: rotate(15deg);
  }
}

/* RESULTS */
.resultsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resultsItems {
  --local-horizontal-margin: 0 !important;
  --local-row-gap: var(--sk-space-24) !important;

  margin-top: 0;
  margin-bottom: var(--sk-space-16);
}

.resultsItem {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.resultsItem > article,
.resultsItem .card {
  height: 100%;
}

}