@layer base {
  .main {
  position: relative;
}

.dropdownMessage {
  padding: var(--sk-space-8) var(--sk-space-16);
  color: var(--sk-color-grey-500);
}

.dropdownSuggestion {
  width: 100%;
  padding: var(--sk-space-8) var(--sk-space-16);
  border: none;
  background-color: var(--sk-color-white);
  color: var(--sk-color-black);
  font-family: var(--sk-font-families-poppins);
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
}

.dropdownSuggestion:hover,
.dropdownSuggestion:focus,
.dropdownSuggestion:active {
  outline: none;
  background-color: var(--sk-color-grey-050);
  cursor: pointer;
}
}