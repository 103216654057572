@layer base {
  .main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--sk-space-56);
}

/* Main actions */
.mainActions {
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  row-gap: var(--sk-space-16);
}

.mainActions__button {
  width: 100%;
}

@media (--sk-medium-viewport) {
  .mainActions {
    grid-auto-flow: column;
    width: auto;
    column-gap: var(--sk-space-32);
  }

  .mainActions__button {
    width: auto;
  }
}

.actionsForDesktop {
  display: none;
}

@media (--sk-medium-viewport) {
  .main {
    margin-top: var(--sk-space-80);
  }

  .mainActions {
    display: none;
  }

  .actionsForDesktop {
    display: flex;
    gap: var(--sk-space-16);
  }
}

/* the footer actions are NOT displayed on the printed version of the page */

@media print {
  .main {
    display: none;
  }
}

}