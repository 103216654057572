@layer base {
  .underlineShape {
  position: relative;
}

.underlineShape__text {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
}

.underlineShape__shape {
  --underline-side-overflow: 5px;
  position: absolute;
  bottom: -3px;
  left: calc(var(--underline-side-overflow) * -1);
  width: calc(100% + calc(2 * var(--underline-side-overflow)));
  min-width: 20px;
  height: 65%;
}

}