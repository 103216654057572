@layer base {
  /* Main */
.main {
  width: 100%;
}

@media (--sk-medium-viewport) {
  .main {
    width: auto;
  }
}

/* Actions */
.actions {
  display: grid;
  justify-items: center;
}

@media (--sk-medium-viewport) {
  .actions {
    justify-items: flex-start;
  }
}

/* Button */

@media (--sk-small-viewport-only) {
  .button {
    width: 100%;
  }
}

}