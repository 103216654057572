@layer base {
  .main {
  --local-row-gap: var(--sk-space-40) !important;
  margin-top: var(--sk-space-40);

  margin-bottom: var(--sk-space-40);
}

@media (--sk-medium-viewport) {
  .main {
    --local-row-gap: var(--sk-space-80) !important;
    margin-top: var(--sk-size-88);

    margin-bottom: var(--sk-size-88);
  }
}

.sectionTitle {
  margin-bottom: var(--sk-space-24);
}

.globalFeedbacks {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-40);
}

.globalFeedback {
  padding-right: var(--sk-space-56);
}

.companyFeedbacks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: var(--sk-space-24);
  gap: var(--sk-space-24);
  border: var(--sk-border-width-2) solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-16);
  text-align: center;
}

.companyFeedbacks___item,
.companyFeedbacks___item > div {
  align-items: center;
  justify-content: center;
}

.companyFeedbacks___item:not(:first-child) {
  position: relative;
  padding-top: var(--sk-space-24);
}

.companyFeedbacks___item:not(:first-child)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: var(--sk-size-40);
  transform: translateX(-50%);
  border-top: 1px solid var(--sk-color-grey-100);
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--sk-space-24);
}
}