@layer base {
  .main {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

.footer {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (--sk-small-viewport-only) {
  .footer {
    flex-direction: column-reverse wrap;
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--sk-space-16);
}

.empty .description {
  color: var(--sk-color-grey-400);
}

.list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  gap: var(--sk-space-16);
}

.linkCard {
  display: flex;
  box-sizing: border-box;
  align-content: space-between;
  align-items: center;
  height: 100%;
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: var(--sk-space-16);
}

.link div {
  flex: 1;
}
}