@layer base {
  .main {
  position: relative;
}

@media (--sk-small-viewport-only) {
  .main {
    display: none;
  }
}

.dropdown {
  padding: var(--sk-space-4) var(--sk-space-8);
}

}