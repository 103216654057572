@layer base {
  .fieldsList {
  margin: var(--sk-space-24) 0 0;
  padding: 0;
  list-style: none;
}

.fieldItem {
  display: flex;
  justify-content: space-between;
  margin-top: var(--sk-space-16);
  gap: var(--sk-space-16);
}

.field {
  flex-grow: 1;
}

.missingMediaList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--sk-space-8);
  margin: var(--sk-space-24) 0 0;
  padding: 0;
  list-style: none;
}

}