@layer base {
  /* Filters Layout  */
.filtersLayout {
  position: relative;
  margin-top: var(--sk-space-8);
}

@media (--sk-medium-viewport) {
  .filtersLayout {
    margin-top: var(--sk-space-16);
  }
}

/* Filters Container  */
.filtersContainer {
  display: flex;
  /*
    These position and z-index stack this element and its descendants above all these positioned elements:
    - the results count
    - the results sort
    - the "save this search" button
    - the job ad cards
  */
  position: relative;
  z-index: calc(var(--z-above-ui-kit-cards) + 1);
  flex-direction: column;
  padding: var(--sk-space-8);
  border-radius: var(--sk-radius-24);
  background-color: var(--sk-color-black);
  gap: var(--gap-size);
  --gap-size: var(--sk-space-8);
}

@media (--sk-medium-viewport) {
  .filtersContainer {
    --gap-size: var(--sk-space-16);
    flex-flow: column wrap;
    padding: var(--sk-space-16);
  }
}

/* Results Layout */
.resultsLayout {
  padding-top: var(--sk-space-56);
  padding-bottom: var(--sk-space-32);
}

}