@layer base {
  .main {
  padding: var(--sk-space-16) 0;
  overflow: hidden;
  word-wrap: break-word;
  hyphens: auto;
}

.title {
  margin-bottom: var(--sk-space-8);
}

@media (--sk-medium-viewport) {
  .main {
    padding: var(--sk-space-16) var(--sk-space-24);
    border-radius: var(--sk-space-8);
    background: var(--sk-color-grey-050);
  }
}

}