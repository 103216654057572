@layer base {
  .fieldsWrapper {
  display: flex;
  flex-direction: column;
  margin-top: var(--sk-space-24);
  gap: var(--sk-space-16);
}

.submitButton {
  width: 100%;
}

}