@layer base {
  .main {
  --icon-size: var(--sk-size-20);
  --field-padding: var(--sk-space-16);
  position: relative;
  width: 100%;
  height: 48px;
}

.main--radius-enabled .main__inputContainer {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.main__pinIcon {
  position: absolute;
  left: var(--field-padding);
  align-self: center;
  width: var(--sk-size-20);
  height: var(--sk-size-20);
  color: var(--sk-color-black);
  pointer-events: none;
}

.main__clearButton {
  position: absolute;
  right: var(--field-padding);
  align-self: center;
  width: var(--sk-size-24);
  height: var(--sk-size-24);
  padding: 0;
  border: none;
  border-radius: var(--sk-radius-8);
  outline: none;
  background-color: transparent;
  color: var(--sk-color-grey-200);
  line-height: 0;
  cursor: pointer;
}

.main__clearButton:active,
.main__clearButton:focus {
  background-color: var(--sk-color-grey-050);
  color: var(--sk-color-black);
}

.main__clearButton:hover {
  color: var(--sk-color-black);
}

.main__inputContainer {
  display: flex;
  height: 100%;
  overflow: hidden;
  border-radius: 0 0 var(--sk-radius-16) var(--sk-radius-16);
  background-color: var(--sk-color-white);
}

.main__input:hover,
.main__input:active {
  outline: none;
  background-color: var(--sk-color-grey-050);
}

.main__input:focus {
  background-color: var(--sk-color-grey-050);
  cursor: text;
}

.main__input {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-right: calc(var(--field-padding) + var(--icon-size) + var(--sk-space-12));
  padding-left: calc(var(--field-padding) + var(--icon-size) + var(--sk-space-12));
  overflow: hidden;
  border: 0;
  outline: none;
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-space-16);
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  line-height: var(--sk-typography-body-1-regular-line-height);
  text-overflow: ellipsis;
}

.main__input::placeholder {
  transition: 0.2s;
  color: var(--sk-color-grey-600);
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-space-16);
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  line-height: var(--sk-typography-body-1-regular-line-height);
}

.main__input:focus::placeholder {
  opacity: 0;
}

.main__input[type="search"]::-webkit-search-cancel-button,
.main__input[type="search"]::-webkit-search-decoration {
  appearance: none;
}

@media (--sk-medium-viewport) {
  .main {
    flex: 1;
    height: 56px;
  }

  .main__inputContainer {
    border-radius: 0 var(--sk-radius-16) var(--sk-radius-16) 0;
  }
}

}