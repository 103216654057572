@layer base {
  .main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--sk-space-16);
  gap: var(--sk-space-16);
}

@media (--sk-medium-viewport) {
  .main___emptyState {
    margin-bottom: var(--sk-space-24);
  }
}

.addLanguageButton {
  width: fit-content;
}

}