@layer base {
  .wrapper {
  display: flex;
  align-items: flex-end;
}

.main {
  --SeeMore-text-length: unset;
  display: -webkit-box;
  overflow: hidden;
  color: unset;
  -webkit-line-clamp: var(--SeeMore-text-length);
  line-clamp: var(--SeeMore-text-length);
  -webkit-box-orient: vertical;
}

.main--expanded {
  display: block;
  -webkit-line-clamp: unset;
  line-clamp: unset;
}

.main--expanded + .seeMoreButton {
  display: none;
}

.seeMoreButton {
  width: fit-content;
  min-width: fit-content;
  text-decoration: underline;
  cursor: pointer;
}

}