@layer base {
  .main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--sk-space-16);
  max-width: 390px;
  margin-right: auto;
  margin-left: auto;
  text-wrap: balance;
}

.description {
  color: var(--sk-color-grey-400);
  text-align: center;
}

}