@layer base {
  .main {
  --gap-size: var(--sk-space-8);
  display: flex;
  gap: var(--gap-size);
}

.divider {
  align-self: center;
  height: var(--sk-space-24);
  transform: scaleY(50%);
}

.divider,
.shortcutFilter {
  display: none;
}

@media (--sk-small-viewport-only) {
  .saveSearchButton {
    display: none;
  }
}

.saveSearchButton {
  margin-left: auto;
}

@media (--sk-large-viewport) {
  .divider,
  .shortcutFilter {
    display: initial;
  }
}

}