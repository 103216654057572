@layer base {
  .main {
  display: flex;
  grid-column: 1 / 5;
  flex-direction: column;
  gap: var(--sk-space-40);
}

.layout {
  --local-row-gap: var(--sk-space-40) !important;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-48);
}

.section {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

.headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.block {
  height: 300px;
  border-radius: var(--sk-radius-12);
  background-color: var(--sk-color-green-200);
}

@media (--sk-medium-viewport) {
  .main {
    grid-column: 1 / 9;
    padding-right: var(--sk-space-12);
  }

  .layout {
    --local-row-gap: var(--sk-space-80) !important;
  }

  .newDashboardMain {
    grid-column: 1 / -1;
  }
}

@media (--sk-large-viewport) {
  .rightColumn {
    margin-left: calc(var(--sk-space-72) - var(--local-column-gap));
  }
}

@media (--major-viewport) {
  .main {
    grid-column: 4 / 10;
    padding: 0 var(--sk-space-24);
  }

  .rightColumn {
    margin-left: var(--sk-space-56);
  }
}

@media (--sk-small-viewport-only) {
  .layout > :first-child {
    order: 1;
  }

  .layout > :nth-child(2) {
    order: 3;
  }

  .layout > :last-child {
    order: 2;
  }
}

}