@layer base {
  .main {
  display: flex;
  position: relative;
  /* to be above the backdrop */
  z-index: calc(var(--z-index-modal) + 1);
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: var(--sk-space-24);
  border: 2px solid var(--sk-color-black);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-white);
  gap: var(--sk-space-24);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.content {
  display: flex;
  gap: var(--sk-space-24);
}

.deleteBtn {
  --sk-action-button-color-background-primary-default-light: var(--sk-color-error-500);
  --sk-action-button-color-background-primary-default-dark: var(--sk-color-error-500);
  --sk-action-button-color-background-primary-hover-light: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-hover-dark: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-active-light: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-active-dark: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-focus-dark: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-focus-light: var(--sk-color-error-550);
}

.actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: var(--sk-space-24);
  gap: var(--sk-space-24);
}

.actions > div {
  display: flex;
  gap: var(--sk-space-16);
}

.actions .confirmContainer {
  justify-content: flex-end;
}

@media (--sk-small-viewport-only) {
  .avatar {
    display: none;
  }

  .actions {
    display: flex;
    flex-direction: column-reverse;
    gap: var(--sk-space-16);
  }

  .actions > div {
    width: 100%;
  }

  .deleteBtn,
  .confirmBtn {
    width: 100%;
  }
}

}