@layer base {
  .main {
  margin-top: var(--sk-space-56);
  margin-bottom: 0;
}

@media (--sk-medium-viewport) {
  .main {
    display: grid;
    grid-auto-columns: 1fr;
    gap: var(--sk-space-24);
    margin-top: var(--sk-space-80);
  }

  .item {
    grid-row: 1;
  }
}

}