@layer base {
  .main {
  margin-top: var(--sk-space-24);
}

@media (--sk-large-viewport) {
  .main {
    margin-top: var(--sk-space-32);
  }
}

.addNewFile {
  width: 100%;
  margin-top: var(--sk-space-8);
}

.error {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
  text-align: center;
}

.header {
  margin-bottom: var(--sk-space-16);
}

.cvOnlyDescription {
  display: flex;
  align-items: center;
  padding: var(--sk-space-16) var(--sk-space-12);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-purple-100);
  gap: var(--sk-space-16);
}

.cvOnlyDescription__icon {
  flex-shrink: 0;
}

}