@layer base {
  /* visual style of the slidebar when it is visible */

@media (--sk-small-viewport-only) {
  .main {
    position: fixed;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    padding: var(--sk-space-16);
    background-color: var(--sk-color-white);
    /* shadow is --sk-color-grey-600 with 15% opacity */
    box-shadow: 0 var(--sk-space-4) var(--sk-space-16) 0 rgba(100 96 108 / 15%);
  }
}

@media (--sk-medium-viewport) {
  .main {
    display: flex;
    position: sticky;
    bottom: 0;
    justify-content: center;
    height: 0;
  }

  .contentBox {
    position: relative;
    /* height of the element + padding bottom to not touch the bottom of the screen */
    bottom: calc(var(--sk-space-48) + var(--sk-space-16));
  }
}

/* management of progressive appearance and disappearance of the slidebar */

.main___appearing {
  animation: fade-in-from-none 0.5s;
}

.main___disappearing {
  animation: fade-out-to-none 0.5s forwards;
}

@keyframes fade-in-from-none {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out-to-none {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* the scroll bar should not be present on the printed version of the page */

@media print {
  .main {
    display: none;
  }
}

}