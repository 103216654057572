@layer base {
  .main {
  display: inline-flex;
  align-items: center;
  height: 26px;
  border: 1px solid var(--sk-color-grey-200);
  border-radius: var(--sk-radius-8);
}

.label {
  padding: 0 var(--sk-space-8) 0 var(--sk-space-8);
  color: var(--sk-color-grey-700);
}

.logo {
  margin-left: var(--sk-space-4);
  border-radius: var(--sk-radius-4);
}

}