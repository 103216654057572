@layer base {
  .main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 364px;
  margin-right: auto;
  margin-left: auto;
  padding-top: var(--sk-space-40);
  padding-bottom: var(--sk-space-40);
}

.icon {
  width: var(--sk-size-104);
  height: var(--sk-size-104);
}

.title,
.description {
  text-align: center;
}

.title {
  margin-top: var(--sk-space-8);
}

.description {
  margin-top: var(--sk-space-8);
}

}