@layer base {
  .main {
  --local-icon-size: var(--sk-space-24);
  position: relative;

  margin: 0;
  overflow: hidden;
}

.main figcaption {
  visibility: hidden;
}

.main::before,
.main::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: currentcolor;
}

.main::before {
  mask-image: var(--filled-src);
  left: calc(var(--local-icon-size) * -5);
}

.main::after {
  mask-image: var(--outlined-src);
}

.main,
.main::before,
.main::after {
  width: calc(var(--local-icon-size) * 5);
  height: var(--local-icon-size);
}

.rating__0 {
  color: var(--sk-color-grey-100);
}

.rating__1::before {
  left: calc(var(--local-icon-size) * -4);
}

.rating__1::after {
  left: calc(var(--local-icon-size));
}

.rating__2::before {
  left: calc(var(--local-icon-size) * -3);
}

.rating__2::after {
  left: calc(var(--local-icon-size) * 2);
}

.rating__3::before {
  left: calc(var(--local-icon-size) * -2);
}

.rating__3::after {
  left: calc(var(--local-icon-size) * 3);
}

.rating__4::before {
  left: calc(var(--local-icon-size) * -1);
}

.rating__4::after {
  left: calc(var(--local-icon-size) * 4);
}

.rating__5::before {
  left: 0;
}

.rating__5::after {
  left: calc(var(--local-icon-size) * 5);
}

}