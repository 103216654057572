@layer base {
  .main {
  position: absolute;
  z-index: var(--z-up-in-the-current-stacking-context);
  box-sizing: border-box;
  width: 100%;
  margin-top: var(--sk-space-8);
  padding: var(--sk-space-8) 0;
  overflow: hidden;
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-white);
  box-shadow: 0 13px 60px 0 rgb(54 46 63 / 10%);
}

}