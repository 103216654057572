@layer base {
  .avatar {
  min-width: var(--sk-Avatar-size);
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-8);
}

.avatar img {
  object-fit: contain;
}

.footer {
  display: none;
}

@media (--sk-medium-viewport) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--sk-space-16);
  }

  .footer__action {
    min-width: fit-content;
  }
}

@media (--sk-large-viewport) {
  .footer {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: var(--sk-space-48);
  }
}

}