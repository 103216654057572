@layer base {
  .servicesWrapper {
  margin-bottom: var(--sk-space-48);
  text-align: center;
}

.servicesTitle {
  margin-bottom: var(--sk-space-16);
}

.serviceColumnList {
  display: grid;
  grid-gap: var(--sk-space-8);
  grid-template-columns: auto;
  margin-bottom: var(--sk-space-16);
  padding: 0;
}

.serviceItem {
  display: flex;
  align-items: center;
  padding: var(--sk-space-16);
  border-radius: var(--sk-radius-16);
  background: var(--sk-color-grey-025);
  text-align: start;
}

.icon {
  flex-shrink: 0;
  margin-right: var(--sk-space-8);
  color: var(--sk-color-purple-500);
}

@media (--sk-medium-viewport) {
  .servicesTitle {
    margin-bottom: var(--sk-space-32);
  }

  .serviceColumnList {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (--sk-large-viewport) {
  .servicesWrapper {
    margin-bottom: var(--sk-space-80);
  }

  .servicesTitle {
    margin-bottom: var(--sk-space-40);
  }
}

}