@layer base {
  /* Aside */
.aside {
  display: none;
}

.aside___isPrefilteredJobSearchPage,
.aside___isRssFeedVisible,
.aside___isPrivateOfferButtonVisible {
  display: initial;
}

.aside___hasNotSavedSearches {
  padding-top: calc(var(--sk-space-48) + var(--sk-space-12));
}

.aside__divider {
  margin-top: var(--sk-space-32);
  margin-bottom: var(--sk-space-32);
}

.aside__divider:first-child {
  display: none;
}

@media (--sk-small-viewport-only) {
  .aside {
    margin-top: var(--sk-space-32);
  }

  .aside .savedSearches,
  .aside .savedSearches + .aside__divider {
    display: none;
  }
}

@media (--sk-medium-viewport) {
  .aside {
    display: initial;
  }
}

}