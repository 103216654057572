@layer base {
  .main {
  display: flex;
  align-items: center;
}

/* Avatar */
.avatar {
  height: var(--sk-space-48);
  padding-right: var(--sk-space-8);
}

.imgProfilePicture {
  object-fit: cover;
}

/* Texts */

.textContent {
  margin-right: auto;
}

.secondaryText {
  color: var(--sk-color-grey-500);
}

}