@layer base {
  .main {
  display: flex;
  grid-column: 1 / 5;
  flex-direction: column;
  gap: var(--sk-space-40);
  margin-top: var(--sk-space-16);
}

@media (--sk-medium-viewport) {
  .main {
    grid-column: 1 / -1;
    margin-top: var(--sk-space-56);
  }
}

}