@layer base {
  .list {
  margin: 0;
  padding: 0;
}

.list__item {
  margin: 0;
  padding: var(--sk-space-16) 0;
  list-style: none;
}

.list__item:first-child {
  padding-top: 0;
}

.list__item:not(:last-child) {
  border-bottom: 1px solid var(--sk-color-dark-20);
}

.list__item:last-child {
  padding-bottom: 0;
}

}