@layer base {
  .trash {
  margin-left: var(--sk-space-32);
  color: var(--sk-color-error-500);
}

.reload {
  margin-left: var(--sk-space-32);
}

}