@layer base {
  .list {
  padding: 0;
}

.item {
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none;
}

.item:not(:last-child)::after {
  content: ' • ';
}

}