@layer base {
  .backdrop {
  position: fixed;
  z-index: var(--z-index-modal);
  inset: 0;
  height: 100dvh;
  animation: 0.2s ease-out 0s 1 show-backdrop;
  opacity: 0.2;
  background-color: var(--sk-color-black);
}

@keyframes show-backdrop {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.2;
  }
}

}