@layer base {
  .main {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

.content {
  --local-row-gap: var(--sk-space-32) !important;
  padding: var(--sk-space-40) var(--sk-space-16);
}

@media (--sk-medium-viewport) {
  .content {
    padding: var(--sk-space-64) 0;
  }
}

.form {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: var(--sk-space-24);
}

@media (--sk-medium-viewport) {
  .form {
    padding: var(--sk-space-48) var(--sk-space-32);
    border-radius: var(--sk-radius-16);
    background-color: var(--sk-color-white);
    box-shadow: 0 var(--sk-space-4) var(--sk-space-16) rgb(0 0 0 / 25%);
    gap: var(--sk-space-40);
  }
}

.formActions {
  display: flex;
  justify-content: center;
  margin-top: var(--sk-space-16);
}

.formActions button {
  width: 100%;
}
}