@layer base {
  .main {
  --inputsGap: var(--sk-space-8);
  --wideCountryCodeWidth: 280px;

  display: flex;
  flex-wrap: wrap;
  gap: var(--inputsGap);
}

.labelWrapper {
  display: flex;
  flex: 1 0 100%;
  gap: var(--sk-space-4);
  align-items: center;
}

.label {
  font-family: var(--sk-typography-body-2-semibold-font-family);
  font-size: var(--sk-typography-body-2-semibold-font-size);
  font-weight: var(--sk-typography-body-2-semibold-font-weight);
  line-height: var(--sk-typography-body-2-semibold-line-height);
}

.subLabel {
  color: var(--sk-form-text-input-secondary-label-color-light);
  font-family: var(--sk-typography-caption-1-font-family);
  font-size: var(--sk-typography-caption-1-font-size);
  font-weight: var(--sk-typography-caption-1-font-weight);
  line-height: var(--sk-typography-caption-1-line-height);
}

.countryCode {
  width: 100%;
}

.countryNumber {
  flex: 1;
}

.countryNumberInner {
  color: var(--sk-color-grey-600);
  font-size: var(--sk-typography-body-2-regular-font-size);
  line-height: var(--sk-typography-body-2-regular-line-height);
}

.countryNumberInner::before {
  content: var(--dialCode); /* dialCode custom properties is set by javascript */
  display: inline-block;
  margin-right: var(--sk-space-8);
}

.main:global(.js-wideContainer) {
  .countryCode {
    width: var(--wideCountryCodeWidth);
  }

  .countryNumber {
    width: calc(100% - var(--wideCountryCodeWidth) - var(--inputsGap));
  }
}

}