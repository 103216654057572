@layer base {
  .main {
  --local-row-gap: var(--sk-space-24);
  padding-top: var(--sk-space-24);
  padding-bottom: var(--sk-space-24);
}

.mainContent {
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--sk-space-24);
}

@media (--sk-medium-viewport) {
  .main {
    padding-top: var(--sk-space-64);
    padding-bottom: var(--sk-space-64);
  }

  .aside {
    margin-right: var(--sk-space-16);
  }
}

}