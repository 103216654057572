@layer base {
  .main {
  padding-top: var(--sk-space-24);
  padding-bottom: var(--sk-space-24);
  background-color: var(--sk-color-grey-025);
}

.cards {
  --local-column-count: 1;
  display: grid;
  gap: var(--sk-space-16);
  grid-template-columns: repeat(var(--local-column-count), minmax(0, 1fr));
}


@media (--sk-medium-viewport) {
  .cards {
    --local-column-count: 2;
  }
}

@media (--sk-large-viewport) {
  .cards {
    --local-column-count: 3;
  }
}

.card {
  position: relative;
  overflow: visible;
  border-color: var(--sk-color-grey-100);
}

.card__link {
  outline: 0;
  color: inherit;
  text-decoration: none;
}

.card__link::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: var(--z-up-in-the-current-stacking-context);
}

.card__link:hover::after {
  border-radius: var(--sk-data-display-card-radius);
  outline: var(--sk-data-display-card-color-border-default-light) 2px solid;
}

.card__link:focus-visible::after {
  border-radius: var(--sk-data-display-card-radius);
  outline: none;
  background: transparent;
  box-shadow:
    var(--sk-ressource-focus-ring-shadow-light-shadow-3),
    var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

}