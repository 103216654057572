@layer base {
  .main {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
  padding: var(--sk-space-16) var(--sk-space-24);
  transition: background-color ease-in-out 150ms;
  border-radius: var(--sk-space-12);
  color: inherit;
  text-decoration: none;
}

.main:hover,
.main:focus {
  background-color: var(--sk-color-grey-050);
}

.content {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  gap: var(--sk-space-16);
}

.testNameAndTime {
  display: flex;
  gap: var(--sk-space-16);
  align-items: center;
  margin-bottom: var(--sk-space-8);
}

.chevron {
  min-width: var(--sk-size-24);
}

@media (--sk-medium-viewport) {
  .main {
    flex-direction: row;
  }
}

}