@layer base {
  .main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.fieldsContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--sk-space-24);
}

.divider {
  margin: var(--sk-space-24) 0;
}

.buttonsContainer {
  display: flex;
}

.saveButton {
  flex-grow: 1;
}

@media (--sk-small-viewport-only) {
  .buttonsContainer {
    margin: auto calc(-1 * var(--sk-space-16)) calc(-1 * var(--sk-space-16));
    padding: var(--sk-space-24) var(--sk-space-16) var(--sk-space-32);
    background-color: var(--sk-color-white);
    box-shadow: 0 var(--sk-space-4) var(--sk-space-16) 0 rgba(100 96 108 / 15%);
  }
}

@media (--sk-medium-viewport) {
  .buttonsContainer {
    margin-top: var(--sk-space-16);
  }
}

@media (--sk-large-viewport) {
  .fieldsContainer {
    grid-template-columns: 1fr 1fr;
    gap: var(--sk-space-16);
  }

  .phoneNumber {
    grid-column: span 2;
    --inputsGap: var(--sk-space-16);
    --wideCountryCodeWidth: calc(50% - calc(var(--inputsGap) / 2));
  }

  .buttonsContainer {
    justify-content: flex-end;
  }

  .saveButton {
    flex-grow: initial;
  }
}

}