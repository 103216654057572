@layer base {
  .main {
  /*
    These position and z-index stack this element and its descendants above all these positioned elements:
    - the job ad cards
  */
  position: relative;
  z-index: var(--z-above-ui-kit-cards);
}

@media (--sk-small-viewport-only) {
  /* Make it a square button with no extra space */
  .button {
    width: var(--sk-size-48);
    height: var(--sk-size-48);
    padding-right: var(--sk-Button-x-padding);
  }
}

@media (--sk-medium-viewport) {
  /* Display the label of the button */
  .button::after {
    content: attr(aria-label);
  }
}

.button___expanded {
  background-color: var(--sk-action-button-color-background-tertiary-hover-light);
}

.options {
  position: absolute;
  right: 0;
  margin-top: var(--sk-space-8);
  padding: var(--sk-space-16);
  border-radius: var(--sk-radius-12);
  background-color: var(--sk-color-white);
  box-shadow: 0 var(--sk-space-4) var(--sk-space-16) rgb(0 0 0 / 25%);
}

.options label {
  white-space: nowrap;
}

}