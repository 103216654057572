@layer base {
  .main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background: transparent;
}

.main [class*="jds-ValueContainer"] {
  max-width: 85%;
}

@media (--sk-medium-viewport) {
  .main {
    flex-direction: row;
    --field-height: 56px;
    width: 100%;
  }
}

.primaryFilter {
  flex: 1;
  min-width: 0;
}

.searchInput {
  flex-grow: 1;
  margin: 0;
}

.searchInputContainer {
  height: 100%;
  border: none;
}

.searchInput [class*="jds-Input"],
.searchInputContainer {
  height: var(--field-height);
  margin: 0;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: var(--sk-radius-16) var(--sk-radius-16) 0 0 !important;
  border-color: transparent !important;
  outline: 0 !important;
  text-overflow: ellipsis;
}

.searchInput [class*="jds-Input"]:placeholder-shown,
.searchInputContainer:placeholder-shown {
  text-overflow: ellipsis;
}

.searchInput [class*="jds-Input"]:hover,
.searchInput [class*="jds-Input"]:focus,
.searchInputContainer:hover,
.searchInputContainer:focus {
  background-color: var(--sk-color-grey-050) !important;
}

.searchInput [class*="jds-Input"]:focus {
  background-color: var(--sk-color-grey-050) !important;
}

.contractFilter {
  height: var(--field-height);
  max-height: var(--field-height);
}

.contractFilter > button {
  border: none;
  border-radius: 0;
}

@media (--sk-medium-viewport) {
  .searchInput [class*="jds-Input"],
  .searchInputContainer {
    flex-grow: 1;
    border-radius: var(--sk-radius-16) 0 0 var(--sk-radius-16) !important;
  }

  .searchInput [class*="jds-Input"] {
    border-radius: var(--sk-radius-16) 0 0 var(--sk-radius-16) !important;
  }
}

@media (--sk-large-viewport) {
  .searchInput {
    flex-grow: 2;
  }
}

@media (--medium-and-less-only) {
  .searchInput {
    flex-grow: 1;
  }
}

}