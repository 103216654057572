@layer base {
  .main {
  overflow: hidden;
}

.main + div {
  z-index: var(--z-up-in-the-current-stacking-context);
  background-color: var(--sk-color-white);
}

@media (--sk-large-viewport) {
  .main {
    overflow: visible;
  }
}

.illustration {
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context);
  pointer-events: none;
  user-select: none;
  scale: 0.33;
}

.illustration--cv {
  rotate: 15deg;
  top: -138px;
  left: -168px;
}

.illustration--info {
  rotate: 15deg;
  top: -80px;
  right: -110px;
}

.illustration--search {
  rotate: 15deg;
  bottom: -160px;
  left: 60px;
}

.illustration--stats {
  right: 0;
  bottom: -40px;
  rotate: -15deg;
}

@media (--sk-medium-viewport) {
  .illustration {
    scale: .5;
  }
}

@media (--sk-large-viewport) {
  .illustration {
    scale: 1;
  }

  .illustration--cv {
    top: -88px;
    left: -128px;
  }

  .illustration--info {
    top: -75px;
    right: -50px;
  }

  .illustration--search {
    bottom: -88px;
    left: 0;
  }

  .illustration--stats {
    right: 90px;
    bottom: 0;
  }
}

.headline {
  z-index: var(--z-up-in-the-current-stacking-context);
  padding-right: var(--sk-space-24);
  padding-left: var(--sk-space-24);
}

@media (--sk-medium-viewport) {
  .headline {
    padding: 0;
  }
}

}