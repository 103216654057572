@layer base {
  .main {
  margin-top: var(--sk-space-16);
  padding: var(--sk-space-24);
  border-radius: var(--sk-radius-12);
  background: var(--sk-color-white);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: var(--sk-space-8);
}

.error {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
  text-align: center;
}

.field {
  margin-top: var(--sk-space-16);
  margin-bottom: var(--sk-space-16);
}

.field input {
  min-width: 0;
}

}